import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "apprentissage-gratuit-pour-toujours",
      "style": {
        "position": "relative"
      }
    }}>{`Apprentissage gratuit pour toujours`}<a parentName="h1" {...{
        "href": "#apprentissage-gratuit-pour-toujours",
        "aria-label": "apprentissage gratuit pour toujours permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Kernel est, avant tout, une communauté d'êtres humains qui tentent d'apprendre ensemble les meilleurs moyens de construire un web véritablement meilleur. Pour ce faire, nous présentons ici un tout nouveau type de support d'apprentissage.`}</p>
    <p>{`Sur la base des commentaires de plus de 800 personnes ayant déjà suivi ce cours, nous avons repris les huit semaines de contenu structuré que nous avions initialement créées et avons produit des flashcards très spécifiques. Celles-ci servent d'"essais réfléchis" et mettent en évidence les idées les plus importantes de chaque élément. L'idée est d'utiliser les flashcards pour construire un système de mémoire à répétition espacée, afin qu'il vous soit facile de vous souvenir de tout ce que vous apprenez dans Kernel.`}</p>
    <p>{`Les flashcards ne sont pas des questions d'examen ! Elles sont auto-évaluées, et vous pouvez les noter comme vous le souhaitez. Nous ne les fournissons que pour vous aider dans votre propre processus et pour rendre plus clair ce que nous pensons être les points les plus pertinents.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Q: Quel est l'intérêt de cette carte flash ?`}</p>
        <p>{`T'aider à te souvenir ce que tu viens de lire !`}</p>
      </Card>
    </Flash>
    <h2 {...{
      "id": "projets-futurs",
      "style": {
        "position": "relative"
      }
    }}>{`Projets futurs`}<a parentName="h2" {...{
        "href": "#projets-futurs",
        "aria-label": "projets futurs permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`En bref, nous construisons le premier environnement d'apprentissage par répétition espacée alimenté par des jetons. Mais qu'est-ce que ces mots peuvent bien signifier ? Eh bien, suivez le cours pour le découvrir...`}</p>
    <p>{`Nous avons pris le travail d'Andy Matuschak et Michael Nielsen sur le `}<a parentName="p" {...{
        "href": "/learn/module-3/remember"
      }}>{`moyen mnémotechnique`}</a>{`, et avons trouvé un moyen de s'assurer qu'il reste effectivement gratuit pour toujours, tout en générant des revenus. Ce qui semble impossible, jusqu'à ce que vous réalisiez ce que signifie réellement `}<a parentName="p" {...{
        "href": "/learn/module-2/engineering"
      }}>{`l'argent programmable`}</a>{`, que Kernel vous enseignera (maintenant à la fois dans le contenu `}<strong parentName="p">{`et`}</strong>{` l'utilisation).`}</p>
    <p>{`Pour utiliser les cartes flash, vous devez vous inscrire et verrouiller un certain DAI. Nous prenons ce DAI et le plaçons dans un protocole de rendement pour vous. La réalisation de toutes les cartes devrait prendre quelques mois à partir du moment où vous vous inscrivez. À la fin de cette période, que vous les ayez complétées avec succès ou non, vous pouvez réclamer votre DAI.`}</p>
    <p>{`Si vous avez choisi de monnayer des jetons LEARN avec vos frais, qui vous garantissent l'accès au Kernel pour toujours, nous ajouterons le rendement à votre montant monnayé. Si vous demandez simplement le remboursement de votre DAI parce que vous ne voulez pas faire partie de la communauté, nous vous rembourserons l'intégralité de votre cotisation, mais nous conserverons le rendement que vous avez gagné pour la maintenance et l'amélioration de nos cours.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      